<template>
  <div>
    <v-dialog 
      fullscreen 
      hide-overlay 
      transition="slide-full-modal-transition" 
      v-model="formOpened" content-class="j_modal" persistent>
      <v-card>
        <div class="modal__header full_title pd_left_3">
          <button class="hamberger_menu" type="button"  @click="onAction('cancel')">
            <v-icon>mdi-menu</v-icon>
          </button>
          <span>Data-Table <span class="sub_title">{{ `(${datagridItem.idx} / ${datagridItem.navIdx}) ` }} {{ datagridItem.name || '' }}</span></span>
        </div>
        <v-card-text class="modal__formControl dark_theme type2 properties10">
          <!-- <v-form v-model="valid" ref="form"> -->
            <v-container>
              <v-card-actions class="modal_toolbar">
                <div class="tool_menus">
                  <div 
                    data-dashboard-quickbtn="dashboard"
                    title="page"
                    :class="catCode == __C_.PAGE_COMPONENT.TYPE_PAGE ? 'selected': ''"
                    @click="onTypeChanged(__C_.PAGE_COMPONENT.TYPE_PAGE)"
                  />
                  <div
                    data-dashboard-quickbtn="modal"
                    title="modal"
                    :class="catCode == __C_.PAGE_COMPONENT.TYPE_MODAL ? 'selected': ''"
                    @click="onTypeChanged(__C_.PAGE_COMPONENT.TYPE_MODAL)"
                  />
                  <div 
                    data-dashboard-quickbtn="timeline-extended" 
                    title="timeline-extended"
                    :class="catCode == __C_.PAGE_COMPONENT.TYPE_P6 ? 'selected': ''"
                    @click="onTypeChanged(__C_.PAGE_COMPONENT.TYPE_P6)"
                  ></div>
                </div>
              </v-card-actions>
              <div class="modal_dark_btn">
                <j-button
                  class="type01 sky  type_full"
                  :class="{ disabled: !resetable }"
                  :disabled="!resetable"
                  @click="onAction('save')"
                >Save</j-button>
                <j-button v-if="modeMod" class="type01 delete  type_full" @click="onAction('delete')">Delete</j-button>
                <div class="check_wrapper">
                  <v-checkbox v-model="update" :label="'Update'"/>
                  <v-checkbox :label="'Page Svg'" />
                  <v-checkbox :label="'Fixed'" />
                </div>
                <button class="help" @click="(e) => { helper=!helper }"></button>
              </div>
              <div class="modal__formControl_inside">
                <v-tabs v-model="stepper" class="wrap__modal_wfix">
                  <v-tab data-type="navigation" title="navigation"></v-tab>
                  <v-tab data-type="database" title="database"></v-tab>
                  <v-tab data-type="title" title="title"></v-tab>
                  <v-tab data-type="table" title="table"></v-tab>
                  <v-tab data-type="table_header" title="header"></v-tab>
                  <v-tab data-type="table_body" title="body"></v-tab>
                  <v-tab data-type="table_group" title="group"></v-tab>
                  <v-tab data-type="timeline" title="timeline"></v-tab>
                  <v-tab data-type="style" title="style"></v-tab>
                  <v-tab data-type="svg" title="svg"></v-tab>

                  <v-tab-item :transition="false" :reverse-transition="false">
                    <j-sysenv-tab-general ref="general" @import-item="onImport" />
                  </v-tab-item>
                  <v-tab-item :transition="false" :reverse-transition="false">
                    <j-sysenv-tab-database ref="database" type="datatable" @save="onSave" />
                  </v-tab-item>
                  <v-tab-item :transition="false" :reverse-transition="false">
                    <j-sysenv-tab-title />
                  </v-tab-item>
                  <v-tab-item :transition="false" :reverse-transition="false">
                    <j-sysenv-tab-table />
                  </v-tab-item>
                  <v-tab-item :transition="false" :reverse-transition="false">
                    <j-sysenv-tab-header />
                  </v-tab-item>
                  <v-tab-item :transition="false" :reverse-transition="false">
                    <j-sysenv-tab-body />
                  </v-tab-item>
                  <v-tab-item :transition="false" :reverse-transition="false">
                    <j-sysenv-tab-group />
                  </v-tab-item>
                  <v-tab-item :transition="false" :reverse-transition="false">
                    <j-sysenv-tab-timeline />
                  </v-tab-item>
                  <v-tab-item :transition="false" :reverse-transition="false">
                    <j-sysenv-tab-style />
                  </v-tab-item>
                  <v-tab-item :transition="false" :reverse-transition="false">
                    <j-sysenv-tab-svg />
                  </v-tab-item>
                </v-tabs>

                <div id="j-datagrid">
                  <div class="j-datagrid-container">
                    <div class="j_datagrid">
                      <div :style="styleLayout">
                        <j-data-grid-title 
                          v-if="showInfoBox"
                          :attrs="titleAttrs" 
                          :legend-items="tableLegend" 
                          :pagination="pagination"
                        />
                        <component
                          :is="dtComponentName"

                          class="canvasAreaLine"
                          item-key="NO"
                          freeze-column="0"
                          :headers="headers"
                          :items="previewItems"
                          :style="`--area-width:${dimention.width+2}px; --area-height: ${dimention.height+1}px`"
                          :summaries="summaryData"
                          :table-attrs="tableAttrs"
                          :table-type="typeCode"
                          @click-config="() => true"
                          @complete="onComplete"
                          @request-action="onRequestedAction"
                        />
                        <!-- <j-data-grid-grouped-column
                          class="canvasAreaLine"
                          item-key="NO"
                          freeze-column="0"
                          :headers="headers"
                          :items="previewItems"
                          :style="`--area-width:${dimention.width+2}px; --area-height: ${dimention.height+1}px`"
                          :summaries="summaryData"
                          :table-attrs="tableAttrs"
                          :table-type="typeCode"
                          @click-config="() => true"
                          @complete="onComplete"
                          @request-action="onRequestedAction"
                        /> -->
                      </div>
                    </div>
                  </div>
                </div>

                <div v-if="helper" id="j-chart-helper">
                  <img :src="helperUrl" />
                </div>
                
              </div>
            </v-container>
          <!-- </v-form> -->
        </v-card-text>
      </v-card>
    </v-dialog>

    <j-alert
      v-model="msgOpen"
      :type="msgInfo.type"
      :title="msgInfo.title"
      :title-description="msgInfo.titleDescription"
      :message="msgInfo.message"
      :button="msgInfo.button"
      :button-text="msgInfo.buttonText"
      @yes="yes()"
      @cancel="msgOpen = false"
    ></j-alert>
    
  </div>
</template>

<script>
import * as d3 from 'd3'
import __C from '@/primitives/_constant_'
import _Dataform from '@/primitives/_dataformDatatable'
import { mapState, mapMutations, mapGetters, mapActions } from "vuex"
import { PageComponentService } from "@/services"
import ChartModalMixin from "@/mixins/chart.modal.common"
import JSysenvTabProps from './SysenvPageDatatable'

import JDatatableP6 from '../../../../lib/jin/uicomponents/JDatatableP6'

export default {
  name: 'sysenv-intended-datagrid-modal',
  mixins: [
    ChartModalMixin
  ],
  components: {
    JDatatableP6,
    ...JSysenvTabProps
  },
  props: {
    item: { type: Object, default: () => ({}) },
    itemKey: null,
    value: { type: Boolean, default: false }
  },
  data: () => ({
    // Service Instances -------------
    pageComponentService: null,
    // activators --------------------
    msgOpen: false,
    // general -----------------------
    dtComponentName: 'JDataGridGroupedColumn',
    dimention: {},
    msgInfo: {
      type: "",
      title: "",
      titleDescription: "",
      message: "",
      button: [true, false, true],
      buttonText: ["Yes", "No", "Cancel"]
    },
    prevItem: {},
    stepper: 0,
    valid: false,
    update: true,

    pagination: { page: 1, rowsPerPage: 50 /* -1 for All */ },
    scvTitleData: null,
    svcTableAttrs: null,

    // temp
    inputText: ''
  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.INTENDED_DATAGRID_ADMIN, [
      'datagridItem', 'previewTitle', 'previewItems', 'summaryData'
    ]),
    ...mapGetters(__C.STORE_NAMESPACE.INTENDED_DATAGRID_ADMIN, [
      'general', 'database', 'tableAttrs'
    ]),

    __C_() { return __C },

    showInfoBox() { return this.tableAvailable && this.tableAttrs.table.showInfoBox == 'Y' },
    styleLayout() {
      if (
        !this.datagridItem.tableAttrs || 
        !this.datagridItem.tableAttrs.layout ||
        this.datagridItem.tableAttrs.layout.applied != 'Y'
      ) return ''
      return `background-color: ${this.datagridItem.tableAttrs.layout.bColor}; padding: ${this.datagridItem.tableAttrs.layout.margin}px;`
    },
    tableAvailable() { return !this.tableAttrs || !this.tableAttrs.table || Object.keys(this.tableAttrs.table).length === 0 ? false : true },

    codePropagated: {
      get() { return this.$store.state.sysenv.codePropagated },
      set(value) { this.$store.commit('sysenv/codePropagate', value) }
    },
    modeNew() { return this.formMode == __C.FORM.EDIT_MODE_NEW },
    modeMod() { return this.formMode == __C.FORM.EDIT_MODE_MOD },
    formMode() {
      if (this.itemKey) return __C.FORM.EDIT_MODE_MOD
      else return __C.FORM.EDIT_MODE_NEW
    },
    formOpened: {
      get() { return this.value },
      set(val) { this.$emit('input', val) }
    },
    headers() {
      if (!this.datagridItem.jsonProps) return []
      try { var jsonProps_ = JSON.parse(this.datagridItem.jsonProps) }
      catch { return [] }
      return jsonProps_
    },
    navIdx() { 
      // console.log(this.$store.state.)
      return this.codePropagated
    },
    tableLegend() { 
      if (
        !this.svcTableAttrs || 
        !this.svcTableAttrs.style ||
        Object.keys(this.svcTableAttrs.style).length === 0
      ) return {}
      return this.svcTableAttrs.style
    },
    titleAttrs() {
      if (
        !this.svcTableAttrs || 
        !this.svcTableAttrs.table
      ) return {}

      let attrs_ = {
        ...JSON.parse(JSON.stringify(this.svcTableAttrs.title || {})),
        ...JSON.parse(JSON.stringify(this.svcTableAttrs.table)),
      }
      attrs_.queries = this.scvTitleData
      if(!attrs_.main) attrs_.main = {}
      if(!attrs_.main.text) attrs_.main.text = this.datagridItem.name

      return attrs_
    },
    updated: {
      get() { return this.$store.state.sysenv.menuUpdated },
      set(value) { this.$store.commit("sysenv/setMenuStatusUpdated", value) }
    },
    catCode() { return this.datagridItem.catCode || '' },
    typeCode() { return this.datagridItem.typeCode || '' },
    helperUrl() { 
      return this.chartItem.ChartNo && this.helper ? require(`../../../assets/helpDoc/logo.png`) : ''
    },
  },
  watch: {
    formOpened(val) {
      if (!val) return

      this.init()

      // Temp ---
      this.$refs.general.refcode = null

      let postInit = () => {
        this.$refs.general.setData(this.formMode)
        this.$refs.database.setData(this.formMode)
        this.prevItem = JSON.parse(JSON.stringify(this.datagridItem))
      }

      if(this.modeNew) {
        this.dtComponentName = 'JDataGridGroupedColumn'

        setTimeout(() => {
          this.setDatagridItem({ 
            version: 'v0.0.1',
            directAccess: 'Y',
            qApplied: 'Q',
            catCode: __C.PAGE_COMPONENT.TYPE_PAGE,
            typeCode: __C.DATATABLE.TYPE_CODE_GENERAL,
            titleAttrs: _Dataform.title
          })

          setTimeout(() => {
            postInit()
          }, 100)
        }, 100);
      } else {
        this.getDatatable()
      }
    }
  },
  created() {
    this.pageComponentService = new PageComponentService()
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.INTENDED_DATAGRID_ADMIN, [
      'setDatagridItem', 'setEmpty'
    ]),
    ...mapActions(__C.STORE_NAMESPACE.INTENDED_DATAGRID_ADMIN, [
      'getDatagridItem', 'getDatatablePreview'
    ]),

    // CRUD Process
    onAction(action) {
      if (action == 'save') this.modeNew ? this.add() : this.edit()
      else if (action == 'delete') this.del()
      else this.close()
    },
    onComplete() {
      setTimeout(() => {
        let tableAttrs_ = JSON.parse(JSON.stringify(this.datagridItem.tableAttrs))
        let dimention_ =  {
          width: Math.ceil(d3.select('.j_datagrid').node().getBoundingClientRect().width),
          height: 0
        }

        if(tableAttrs_.layout.dimention && tableAttrs_.layout.dimention.width == dimention_.width) return
        
        tableAttrs_.layout.dimention = dimention_
        this.setDatagridItem({ tableAttrs: tableAttrs_ })
      }, 1000)
    },
    onImport(idx) {
      // this.datagridItem.idx : original id of the data owner
      let idx___ = this.datagridItem.idx
      let primeData = { 
        idx: this.datagridItem.idx,
        phaseCode: this.datagridItem.phaseCode,
        groupCode: this.datagridItem.groupCode,
        itemCode: this.datagridItem.itemCode,
        subItemCode: this.datagridItem.subItemCode,
        version: 'v0.0.1'
      }
      
      this.getDatatable(idx).then(res => {
        if(res.idx == idx___) delete primeData.version
        // Needs to time-delay to wait for the previous $refs.general.setData
        // process is finished in the getChart(code) process.
        setTimeout(() => {
          if(this.modeMod) this.setDatagridItem(primeData)
          this.$refs.general.setData(this.formMode)
        }, 500)
      })
    },
    onRequestedAction(action) {
      console.log(action);
    },
    onSave() {
      if(this.modeNew) return

      this.versionUp()

      let reqItem = JSON.parse(JSON.stringify(this.datagridItem))
      this.setTitleMain(reqItem)
      this.pageComponentService.updDatatable(reqItem, _ => {
        this.getDatatable().then(() => { this.$emit("updated", true) })
      })
    },
    onTypeChanged(catCode) {
      if(!catCode) return
      if(this.datagridItem.catCode == catCode) return

      if(catCode == __C.PAGE_COMPONENT.TYPE_P6) this.dtComponentName = 'JDatatableP6'
      else this.dtComponentName = 'JDataGridGroupedColumn'

      this.setDatagridItem({})

      setTimeout(() => {
        this.setDatagridItem({ 
          ...this.prevItem,
          catCode: catCode,
        })
      }, 100);
    },

    add() {
      let reqItem = JSON.parse(JSON.stringify(this.datagridItem))
      this.setTitleMain(reqItem)
      this.pageComponentService.putDatatable(reqItem, () => {
        this.close()
        this.$emit("updated", true)
        this.menuUpdated = true
      })
    },
    edit() {
      this.onSave()
    },
    del() {
      this.yes = () => {
        this.msgOpen = false
        this.pageComponentService.delDatatable(this.datagridItem.idx, () => {
          this.close()
          this.$emit("updated", true)
          this.menuUpdated = true
        })
        this.yes = () => { }
      }
      this.msgInfo.type = "WARN"
      this.msgInfo.title = "Action Approval"
      this.msgInfo.titleDescription = "Important Notification"
      this.msgInfo.message = "Do you want to delete current information?"
      this.msgInfo.buttonText[0] = "Delete"
      this.msgOpen = true
    },
    getDatatable(idx=null) {
      return new Promise(resolve => {
        this.pageComponentService.getDatatable(idx || this.itemKey, (res) => {
          // Temporal ---
          if(!res.titleAttrs) res.titleAttrs = _Dataform.title
          if(!res.titleAttrs.main) res.titleAttrs.main = _Dataform.title.main
          if(!res.titleAttrs.sub) res.titleAttrs.sub = _Dataform.title.sub
          if(!res.titleAttrs.queries) res.titleAttrs.queries = _Dataform.title.queries
          if(!res.titleAttrs.query) res.titleAttrs.query = _Dataform.title.query
          if(!res.titleAttrs.note) res.titleAttrs.note = _Dataform.title.note
          // --- --------

          if(res.catCode == __C.PAGE_COMPONENT.TYPE_P6) this.dtComponentName = 'JDatatableP6'
          else this.dtComponentName = 'JDataGridGroupedColumn'

          setTimeout(() => {
            this.setDatagridItem(res)
            this.$refs.general.setData(this.formMode)
            this.$refs.database.setData(this.formMode)
            this.prevItem = JSON.parse(JSON.stringify(this.datagridItem))

            this.getDatatablePreview(res.idx).then(resdt => {
              this.pagination = resdt.pagination
              this.svcTableAttrs = resdt.tableAttrs
              this.scvTitleData = resdt.titleData
            })

            resolve(res)
          }, 100);
        })
      })
    },
    init() {
      this.stepper = 0
      // every time came into the modal, either the mode new or edit,
      // whole the store's data should be initialized.
      this.setEmpty()
      this.prevItem = {}
      this.dimention = {}
    },
    resetable() {
      if (!this.chartMaster) return false
      if (this.modeNew) {
        return false
      } else {
        return false
      }
    },
    setTitleMain(item_) {
      if(!item_.titleAttrs.main.text) item_.titleAttrs.main.text = item_.name
    },
    versionUp() {
      var version_ = this.datagridItem.version.toString().replace(/[^\d.]/g, '').split('.')

      // JSON - Datagrid Header Option 값 변경시 v00.00.XX 변경
      if (this.prevItem.jsonProps != this.datagridItem.jsonProps) version_[2] = ++version_[2]
      if (version_[2] > 99) { version_[2] = version_[2] - 100; version_[1] = ++version_[1] }

      // Database & Table/View 값 변경시 v00.XX.00 변경
      if (this.prevItem.dbName != this.datagridItem.dbName || this.prevItem.tableName != this.datagridItem.tableName) version_[1] = ++version_[1]
      if (version_[1] > 99) { version_[1] = version_[1] - 100; version_[0] = ++version_[0] }

      this.setDatagridItem({ version: "v" + version_.join('.') })
    },
    close() {
      this.formOpened = false
    },
  }
}
</script>

