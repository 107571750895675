export default {
  title: {
    main: {
      text: '',
      font: 'roboto',
      fontStyle: 'regular',
      fontSize: 16,
      color: '#fff',
    },
    sub: {
      text: '',
      font: 'roboto',
      fontStyle: 'regular',
      fontSize: 12,
      color: '#bdbcbc',
    },
    queries: {
      _1st: '',
      _2nd: '',
      _3rd: '',
      _4th: '',
      _5th: '',
    },
    query: {
      font: 'roboto',
      fontStyle: 'regular',
      fontSize: 10,
      color: '#bdbcbc',
    },
    note: {
      text: '',
      font: 'roboto',
      fontStyle: 'regular',
      fontSize: 9,
      color: '#bdbcbc',
    },
  },
  table: {
    layout: {
      applied: 'N',
      bColor: 'transparent',
      margin: 0
    },
    border: {
      applied: 'Y',
      top: { width: 1, color: 'rgba(207, 216, 220, 1)' },
      right: { width: 1, color: 'rgba(207, 216, 220, 1)' },
      bottom: { width: 1, color: 'rgba(207, 216, 220, 1)' },
      left: { width: 1, color: 'rgba(207, 216, 220, 1)' },
    },
    pagination: {
      type: 'page',         // ['scroll', 'page']
      fontStyle: 'arrow',   // ['arrow', 'number']
      showPageNo: 'Y',
      rowsPerPage: 40,
    },
    showInfoBox: 'Y',
    showTitle: 'Y',
    showLegend: 'Y',
    showSearchInput: 'Y',
    showExcelExport: 'Y'
  },
  header: {
    font: 'roboto',
    border: { width: 1, color: 'rgba(207, 216, 220, 1)' },
    background: {
      color: 'transparent',
      gradient: '',
      opacity: 1,
    },
    styles: [
      {
        height: 28,
        fontStyle: 'regular',
        fontSize: 11,
        tColor: '#262626',
        background: {
          color: 'transparent',
          gradient: '',
          opacity: 1,
        },
        border: { width: 1, color: 'rgba(207, 216, 220, 1)' },
      },
      {
        height: 20,
        fontStyle: 'regular',
        fontSize: 10,
        tColor: '#262626',
        background: {
          color: 'transparent',
          gradient: '',
          opacity: 1,
        },
        border: { width: 1, color: 'rgba(207, 216, 220, 1)' },
      },
      {
        height: 16,
        fontStyle: 'regular',
        fontSize: 9,
        tColor: '#262626',
        background: {
          color: 'transparent',
          gradient: '',
          opacity: 1,
        },
        border: { width: 1, color: 'rgba(207, 216, 220, 1)' },
      },
      {
        height: 16,
        fontStyle: 'regular',
        fontSize: 9,
        tColor: '#262626',
        background: {
          color: 'transparent',
          gradient: '',
          opacity: 1,
        },
        border: { width: 1, color: 'rgba(207, 216, 220, 1)' },
      },
    ]
  },
  body: {
    textMargin: 3,
    summary: {
      height: 24,
      font: 'roboto',
      fontStyle: 'regular',
      fontSize: 10,
      color: '#626262',
      bColor: '',
      border: { width: 1, color: 'rgba(207, 216, 220, 1)' },
      styles: [
        {
          border: { width: 1, color: 'rgba(207, 216, 220, 1)' },
        },
        {
          border: { width: 1, color: 'rgba(207, 216, 220, 1)' },
        },
        {
          border: { width: 1, color: 'rgba(207, 216, 220, 1)' },
        },
        {
          border: { width: 1, color: 'rgba(207, 216, 220, 1)' },
        },
      ]
    },
    cell: {
      height: 21,
      font: 'roboto',
      fontStyle: 'regular',
      fontSize: 10,
      color: '#626262',
      styles: [
        {
          border: { width: 1, color: 'rgba(207, 216, 220, 1)' },
        },
        {
          border: { width: 1, color: 'rgba(207, 216, 220, 1)' },
        },
        {
          border: { width: 1, color: 'rgba(207, 216, 220, 1)' },
        },
        {
          border: { width: 1, color: 'rgba(207, 216, 220, 1)' },
        },
      ]
    },
    merge: 'N',
    freeze: {
      applicable: 'Y',
      summaryRow: 'Y',
      columnNo: 0,
      borderWeight: 1,
      borderColor: '#9ab2bc',
    },
  },
}
