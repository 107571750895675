<template>
  <v-flex v-model="tabActivator" class="chart_properties" wrap>
    <div class="chart_properties_header">
      <span>Title</span>
      <small class="type_ref01">Properties</small>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Text
        <a class="close" v-on:click="collapsed['text'] = !collapsed['text']"></a>
      </div>
      <v-flex class="control cell" v-show="collapsed['text']">
        <div>
          <span class="label">Main Title</span>
          <div class="type_inner">
            <input v-model="titleMainText" type="text" placeholder="Main Title" class="type100">
            <div class="type_common_input type_color_float">
              <label class="type_gray second_depth">Font</label>
              <div class="input_flex_wrap_end">
                <select v-model="titleMainFont" class="type70" dir="rtl">
                  <option v-for="option in fontOptions" :key="option.index" :value="option.value">{{option.text}}</option>
                </select>
                <select v-model="titleMainFontStyle" dir="rtl" class="type70" style="margin-left: 0.2rem">
                  <option v-for="option in fontStyleOptions" :key="option.index" :value="option.value">{{option.text}}</option>
                </select>
              </div>
            </div>
              <div class="type_common_input type_color_float">
              <label class="type_gray second_depth">Size / Color</label>
              <div class="input_flex_wrap_end">
                <input v-model="titleMainFontSize" type="text" class="type22" />
                <j-color-picker  v-model="titleMainFontColor"/>
              </div>
            </div>
          </div>
        </div>
        <div>
          <span class="label">Sub Title</span>
          <div class="type_inner">
            <input v-model="titleSubText" type="text" placeholder="Sub Title" class="type100">
            <div class="type_common_input type_color_float">
              <label class="type_gray second_depth">Font</label>
              <div class="input_flex_wrap_end">
                <select v-model="titleSubFont" class="type70" dir="rtl">
                  <option v-for="option in fontOptions" :key="option.index" :value="option.value">{{option.text}}</option>
                </select>
                <select v-model="titleSubFontStyle" dir="rtl" class="type70" style="margin-left: 0.2rem">
                  <option v-for="option in fontStyleOptions" :key="option.index" :value="option.value">{{option.text}}</option>
                </select>
              </div>
            </div>
              <div class="type_common_input type_color_float">
              <label class="type_gray second_depth">Size / Color</label>
              <div class="input_flex_wrap_end">
                <input v-model="titleSubFontSize" type="text" class="type22" />
                <j-color-picker  v-model="titleSubFontColor"/>
              </div>
            </div>
          </div>
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        SQL Text
        <a class="close" v-on:click="collapsed['sql'] = !collapsed['sql']"></a>
      </div>
      <v-flex class="control cell" v-show="collapsed['sql']">
        <div>
        <span class="label">Data-Source</span>
          <div v-for="(name, i) in queryNames" :key="name.index" class="type_inner">
            <div class="type_common_select">
              <label class="type_gray second_depth">Query {{i+1}}</label>
              <v-btn @click.stop="exp[`Q${i+1}`]=true" class="type_openTextarea">{{ getQueryParts(name) }}</v-btn>
            </div>
            <j-modal-scripter 
              v-model="dataform.queries[name]" 
              :title="`Title Query ${i+1}`"
              :sub-title="`${name} Query for Title Description`"
              :buttons="{validate: true, save:false}"
              :message="validated[name].message"
              :opened="exp[`Q${i+1}`]"
              :valid="validated[name].valid"
              @close="exp[`Q${i+1}`]=false;"
              @input="updateAttr"
              @validate="onValidate(name)"
            />
          </div>
          <div class="type_inner">
            <div class="type_common_input type_color_float">
              <label class="type_gray second_depth">Font</label>
              <div class="input_flex_wrap_end">
                <select v-model="titleQueryFont" class="type70" dir="rtl">
                  <option v-for="option in fontOptions" :key="option.index" :value="option.value">{{option.text}}</option>
                </select>
                <select v-model="titleQueryFontStyle" dir="rtl" class="type70" style="margin-left: 0.2rem">
                  <option v-for="option in fontStyleOptions" :key="option.index" :value="option.value">{{option.text}}</option>
                </select>
              </div>
            </div>
              <div class="type_common_input type_color_float">
                <label class="type_gray second_depth">Size / Color</label>
                <div class="input_flex_wrap_end">
                  <input v-model="titleQueryFontSize" type="text" class="type22" />
                  <j-color-picker  v-model="titleQueryFontColor"/>
                </div>
              </div>
            </div>
          </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Note
        <a class="close" v-on:click="collapsed['note'] = !collapsed['note']"></a>
      </div>
      <v-flex class="control cell" v-show="collapsed['note']">
        <div>
          <div class="type_inner">
            <v-btn @click.stop="exp.N=true" class="type_openTextarea type100 margin_bt_2">{{ getNoteParts() }}</v-btn>
            <j-modal-scripter 
              v-model="titleNoteText" 
              title="Note"
              sub-title="Datalist Description"
              :buttons="{validate: false, save:false}"
              :opened="exp.N"
              @close="exp.N=false;"
            />
            <div class="type_common_input type_color_float">
              <label class="type_gray second_depth">Font</label>
              <div class="input_flex_wrap_end">
                <select v-model="titleNoteFont" class="type70" dir="rtl">
                  <option v-for="option in fontOptions" :key="option.index" :value="option.value">{{option.text}}</option>
                </select>
                <select v-model="titleNoteFontStyle" dir="rtl" class="type70" style="margin-left: 0.2rem">
                  <option v-for="option in fontStyleOptions" :key="option.index" :value="option.value">{{option.text}}</option>
                </select>
              </div>
            </div>
              <div class="type_common_input type_color_float">
              <label class="type_gray second_depth">Size / Color</label>
              <div class="input_flex_wrap_end">
                <input v-model="titleNoteFontSize" type="text" class="type22" />
                <j-color-picker  v-model="titleNoteFontColor"/>
              </div>
            </div>
          </div>
        </div>
      </v-flex>
    </div>
  </v-flex>
</template>

<script>
import __C from '@/primitives/_constant_'
import _Dataform from '@/primitives/_dataformDatatable'
import { mapState, mapMutations } from "vuex"
import { ChartLibraryService } from "@/services"
import { QueryValidationMixin } from "@/mixins/sqlValidator"
import StaticOptions from '@/mixins/staticOptions'

export default {
  name: 'j-sysenv-tab-title',
  mixins: [
    QueryValidationMixin,
    StaticOptions
  ],
  data: () => ({
    // Service Instances -------------
    chartLibraryService: null,

    collapsed: {
      text: true,
      sql: true,
      note: true,
    },
    dataform: {},
    exp: {
      Q1: false,
      Q2: false,
      Q3: false,
      Q4: false,
      Q5: false,
      N: false,
    },
    validated: {
      _1st: { valid: true, message: '' },
      _2nd: { valid: true, message: '' },
      _3rd: { valid: true, message: '' },
      _4th: { valid: true, message: '' },
      _5th: { valid: true, message: '' },
    },

    typeOptions: [{ text: 'Scroll', value: 'scroll' },{ text: 'Page', value: 'page' }],
    pageOptions: [{ text: 'Number', value: 'number' },{ text: 'Arrow', value: 'arrow' }],

    tabActivator: null,
  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.INTENDED_DATAGRID_ADMIN, ['datagridItem']),
    
    queryNames() { return Object.keys(_Dataform.title.queries) },

    titleMainText: {
      get() { return this.dataform.main.text },
      set(val) { this.dataform.main.text = val; this.updateAttr(); }
    },
    titleMainFont: {
      get() { return this.dataform.main.font },
      set(val) { this.dataform.main.font = val; this.updateAttr(); }
    },
    titleMainFontStyle: {
      get() { return this.dataform.main.fontStyle },
      set(val) { this.dataform.main.fontStyle = val; this.updateAttr(); }
    },
    titleMainFontSize: {
      get() { return this.dataform.main.fontSize },
      set(val) { this.dataform.main.fontSize = val; this.updateAttr(); }
    },
    titleMainFontColor: {
      get() { return this.dataform.main.color },
      set(val) { this.dataform.main.color = val; this.updateAttr(); }
    },
    titleSubText: {
      get() { return this.dataform.sub.text },
      set(val) { this.dataform.sub.text = val; this.updateAttr(); }
    },
    titleSubFont: {
      get() { return this.dataform.sub.font },
      set(val) { this.dataform.sub.font = val; this.updateAttr(); }
    },
    titleSubFontStyle: {
      get() { return this.dataform.sub.fontStyle },
      set(val) { this.dataform.sub.fontStyle = val; this.updateAttr(); }
    },
    titleSubFontSize: {
      get() { return this.dataform.sub.fontSize },
      set(val) { this.dataform.sub.fontSize = val; this.updateAttr(); }
    },
    titleSubFontColor: {
      get() { return this.dataform.sub.color },
      set(val) { this.dataform.sub.color = val; this.updateAttr(); }
    },
    _1st: {
      get() { return this.dataform.queries._1st },
      set(val) { this.dataform.queries._1st = val; this.updateAttr(); }
    },
    _2nd: {
      get() { return this.dataform.queries._2nd },
      set(val) { this.dataform.queries._2nd = val; this.updateAttr(); }
    },
    _3rd: {
      get() { return this.dataform.queries._3rd },
      set(val) { this.dataform.queries._3rd = val; this.updateAttr(); }
    },
    _4th: {
      get() { return this.dataform.queries._4th },
      set(val) { this.dataform.queries._4th = val; this.updateAttr(); }
    },
    _5th: {
      get() { return this.dataform.queries._5th },
      set(val) { this.dataform.queries._5th = val; this.updateAttr(); }
    },
    titleQueryFont: {
      get() { return !this.dataform.query ? '' : this.dataform.query.font },
      set(val) { 
        if(!this.dataform.query) this.dataform.query = {}
        this.dataform.query.font = val
        this.updateAttr()
      }
    },
    titleQueryFontStyle: {
      get() { return !this.dataform.query ? '' : this.dataform.query.fontStyle },
      set(val) { 
        if(!this.dataform.query) this.dataform.query = {}
        this.dataform.query.fontStyle = val
        this.updateAttr()
      }
    },
    titleQueryFontSize: {
      get() { return !this.dataform.query ? '' : this.dataform.query.fontSize },
      set(val) { 
        if(!this.dataform.query) this.dataform.query = {}
        this.dataform.query.fontSize = val
        this.updateAttr()
      }
    },
    titleQueryFontColor: {
      get() { return !this.dataform.query ? '' : this.dataform.query.color },
      set(val) { 
        if(!this.dataform.query) this.dataform.query = {}
        this.dataform.query.color = val
        this.updateAttr()
      }
    },
    titleNoteText: {
      get() { return this.dataform.note.text },
      set(val) { this.dataform.note.text = val; this.updateAttr(); }
    },
    titleNoteFont: {
      get() { return this.dataform.note.font },
      set(val) { this.dataform.note.font = val; this.updateAttr(); }
    },
    titleNoteFontStyle: {
      get() { return this.dataform.note.fontStyle },
      set(val) { this.dataform.note.fontStyle = val; this.updateAttr(); }
    },
    titleNoteFontSize: {
      get() { return this.dataform.note.fontSize },
      set(val) { this.dataform.note.fontSize = val; this.updateAttr(); }
    },
    titleNoteFontColor: {
      get() { return this.dataform.note.color },
      set(val) { this.dataform.note.color = val; this.updateAttr(); }
    },
  },
  watch: {
    'datagridItem.titleAttrs': {
      handler(val) {
        if (!val || Object.keys(val).length === 0) return
        this.dataform = JSON.parse(JSON.stringify(val))
      },
      deep: true
    }
  },
  created() {
    this.chartLibraryService = new ChartLibraryService()
    this.dataform = _Dataform.title
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.INTENDED_DATAGRID_ADMIN, ['setDatagridItem']),

    onValidate(typeName) {
      this.validateSql(this[typeName], 'Y', 'Y').then(res => {
        this.validated[typeName] = {
          valid: res.valid,
          message: res.message
        }
        if(res.valid && res.results.jsonString) {     
          console.log(JSON.parse(res.results.jsonString))
        }   
      })
    },

    getQueryParts(name) {
      if(!this.dataform.queries[name]) return ''
      return this.dataform.queries[name].substring(0, 30) + ' ...'
    },
    getNoteParts() {
      if(!this.dataform.note.text) return ''
      return this.dataform.note.text.substring(0, 40) + ' ...'
    },
    surfix(i) {
      let surfix_ = ['st', 'nd', 'rd', 'th']
      if (i < 3) return surfix_[i]
      return surfix_[3]
    },
    updateAttr() {
      this.setDatagridItem({ titleAttrs: JSON.parse(JSON.stringify(this.dataform)) })
    }
  }
}
</script>
